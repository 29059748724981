import React, { Component } from 'react';
import axios from 'axios';
import emailjs from 'emailjs-com';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

class MainHomeComponent extends Component {
  state = {
    name: '',
    email: '',
    service: '',

    mobileNumber: '',
    specialRequest: '',
    alertMessage: ''
  };

  componentDidMount() {
    // Initialize EmailJS
    emailjs.init('2LkXttzKAVGsn4L25');
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const btn = document.getElementById('button');
    btn.value = 'Sending...';

    const serviceID = 'service_o02f64a';
    const templateID = 'template_zlkvznm';

    emailjs.sendForm(serviceID, templateID, event.target)
      .then(() => {
        btn.value = 'Send Email';
        alert('Sent!');
      })
      .catch((err) => {
        btn.value = 'Send Email';
        alert(JSON.stringify(err));
      });
  };

  handleOrderNowClick = () => {
    const token = localStorage.getItem('token'); // Check if the user is logged in
    const navigate = useNavigate();

    if (!token) {
        // If not logged in, redirect to the login page
        navigate('/LoginPage'); // Navigate to login page
    } else {
        // If logged in, redirect to the OrderBook page
        navigate('/OrderBook'); // Navigate to order book page
    }
};

  render() {
    const { alertMessage } = this.state;
    const token = localStorage.getItem('token');
    return (
      <div>
        <meta charSet="utf-8" />
        <title>BD Restaurant</title>
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <meta content name="keywords" />
        <meta content name="description" />
        {/* Favicons */}
        <link href="img/favicon.ico" rel="icon" />
        <link href="img/apple-touch-icon.png" rel="apple-touch-icon" />
        {/* Google Fonts */}
        <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600|Nunito:600,700,800,900" rel="stylesheet" />
        {/* Bootstrap CSS File */}
        <link href="vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet" />
        {/* Libraries CSS Files */}
        <link href="vendor/font-awesome/css/font-awesome.min.css" rel="stylesheet" />
        <link href="vendor/animate/animate.min.css" rel="stylesheet" />
        <link href="vendor/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
        <link href="vendor/tempusdominus/css/tempusdominus-bootstrap-4.min.css" rel="stylesheet" />
        {/* Main Stylesheet File */}
        <link href="css/style.css" rel="stylesheet" />
        <main id="main">

          <Header></Header>
          {/* Top Header Start */}

          {/* Top Header End */}
          {/* Header Start */}
          {/* Header End */}
          {/* About Section Start*/}
          <section id="about">
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <div className="about-col-left">
                    <img className="img-fluid" src="img/about-us.jpg" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-col-right">
                    <div className="row">
                      <div className="col-md-12">
                        <header className="section-header">
                          <h3>About Us</h3>
                        </header>
                        <ul className="icon">
                          <li><a href="#" className="fa fa-twitter" /></li>
                          <li><a href="#" className="fa fa-facebook" /></li>
                          <li><a href="#" className="fa fa-pinterest" /></li>
                          <li><a href="#" className="fa fa-google-plus" /></li>
                        </ul>
                        <p>
                          Welcome to our 5-star home service experience, where luxury, comfort, and personalized attention come together. We take pride in delivering top-tier services that cater to your every need. Whether it’s a relaxing getaway, a business trip, or a family stay, our focus is on making your time with us truly exceptional.                        </p>
                        <p>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="about-col">
                          <h4>Our Features</h4>
                          <p>
                            Personalized Service: From the moment you arrive, our dedicated team is here to ensure your comfort, offering tailored services that fit your preferences.
                            24/7 Support: Enjoy round-the-clock assistance with everything from room service to concierge support, ensuring all your needs are met at any time.
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="about-col">
                          <h4>Our Facilities</h4>
                          <p>
                            Spa & Wellness Center: Relax and rejuvenate with world-class spa treatments, a fully equipped gym, and wellness programs tailored to your health goals.
                            Swimming Pool: Take a dip in our temperature-controlled pool or lounge in our sun-kissed poolside cabanas.
                            Conference & Event Spaces: Host business meetings or private events in our spacious,
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* About Section End*/}
          {/* Reservations Section Start */}
          {/* <section id="reservations">
            <div className="container">
              <header className="section-header">
                <h3>Book Chef</h3>
              </header>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-row">
                    <div className="control-group col-md-3">
                      <div className="form-group">
                        <div className="input-group date" id="date" data-target-input="nearest">
                          <input type="text" className="form-control datetimepicker-input" placeholder="Date" data-target="#date" />
                          <div className="input-group-append" data-target="#date" data-toggle="datetimepicker">
                            <div className="input-group-text"><i className="fa fa-calendar" /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="control-group col-md-3">
                      <div className="form-group">
                        <div className="input-group date" id="time" data-target-input="nearest">
                          <input type="text" className="form-control datetimepicker-input" placeholder="Time" data-target="#time" />
                          <div className="input-group-append" data-target="#time" data-toggle="datetimepicker">
                            <div className="input-group-text"><i className="fa fa-calendar" /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="control-group col-md-3">
                      <select className="custom-select">
                        <option selected>Party Size</option>
                        <option value={1}>1 Person</option>
                        <option value={2}>2 People</option>
                        <option value={3}>3 People</option>
                        <option value={4}>4 People</option>
                        <option value={5}>5 People</option>
                        <option value={6}>6 People</option>
                        <option value={7}>7 People</option>
                        <option value={8}>8 People</option>
                        <option value={9}>9 People</option>
                        <option value={10}>10 People</option>
                      </select>
                    </div>
                    <div className="control-group col-md-3">
                      <button className="btn btn-block btn-book">Book Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* Reservations Section End */}
          {/* Menu Section Start */}
          <section id="food-menu">
            <div className="container">
              <header className="section-header">
                <h3>delicious Food Menu</h3>
              </header>
              <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-3">
            <div className="single-menu">
                <img className="img-fluid" src="img/menu-1.jpg" alt="Italian Cuisine" />
                <h4>Chinese</h4>
               
                <p>Diverse and flavorful cuisine from China, featuring noodles, rice, dumplings, and various sauces.</p>
              
                {token ? (
                     <a href="/OrderBook">Order Now</a>
                ) : (
                  
                      <a href="/LoginPage">Order Now</a>
                )}

               
            </div>
        </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className="single-menu">
                    <img className="img-fluid" src="img/menu-2.jpg" />
                    <h4>Maharashtrian</h4>
                   
                    <p>Traditional cuisine from Maharashtra, India, known for its spicy flavors and unique ingredients.</p>
                    {token ? (
                     <a href="/OrderBook">Order Now</a>
                ) : (
                  
                      <a href="/LoginPage">Order Now</a>
                )}
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className="single-menu">
                    <img className="img-fluid" src="img/menu-3.jpg" />
                    <h4>Indian</h4>
                   
                    <p> Rich, aromatic dishes with a variety of spices and herbs, covering different regional styles.</p>
                    {token ? (
                     <a href="/OrderBook">Order Now</a>
                ) : (
                  
                      <a href="/LoginPage">Order Now</a>
                )}
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className="single-menu">
                    <img className="img-fluid" src="img/menu-4.jpg" />
                    <h4>Mexican</h4>
                   
                    <p>Vibrant cuisine known for tacos, tortillas, and rich flavors with a hint of spice.</p>
                    {token ? (
                     <a href="/OrderBook">Order Now</a>
                ) : (
                  
                      <a href="/LoginPage">Order Now</a>
                )}
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className="single-menu">
                    <img className="img-fluid" src="img/menu-5.jpg" />
                    <h4>Japanese</h4>
                    
                    <p>Minimalist cuisine emphasizing fresh ingredients like sushi, sashimi, and delicate flavors.</p>
                    {token ? (
                     <a href="/OrderBook">Order Now</a>
                ) : (
                  
                      <a href="/LoginPage">Order Now</a>
                )}
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className="single-menu">
                    <img className="img-fluid" src="img/menu-6.jpg" />
                    <h4>French</h4>
                   
                    <p>Elegant and refined cuisine, famous for pastries, cheeses, and rich sauces.</p>
                    {token ? (
                     <a href="/OrderBook">Order Now</a>
                ) : (
                  
                      <a href="/LoginPage">Order Now</a>
                )}
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className="single-menu">
                    <img className="img-fluid" src="img/menu-7.jpg" />
                    <h4>Mediterranean </h4>
                   
                    <p>Healthy cuisine featuring olive oil, fresh vegetables, and simple, hearty flavors.</p>
                    {token ? (
                     <a href="/OrderBook">Order Now</a>
                ) : (
                  
                      <a href="/LoginPage">Order Now</a>
                )}
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className="single-menu">
                    <img className="img-fluid" src="img/menu-8.jpg" />
                    <h4>Thai</h4>
                   
                    <p>Known for its balance of sweet, sour, salty, and spicy flavors, often using ingredients like lemongrass and coconut milk.</p>
                    {token ? (
                     <a href="/OrderBook">Order Now</a>
                ) : (
                  
                      <a href="/LoginPage">Order Now</a>
                )}
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 ">
                  <div className="single-menu">
                    <img className="img-fluid" src="img/menu-9.jpg" />
                    <h4>Korean</h4>
                  
                    <p>Cuisine rich in fermented flavors, featuring dishes like kimchi, bulgogi, and bibimbap.</p>
                    {token ? (
                     <a href="/OrderBook">Order Now</a>
                ) : (
                  
                      <a href="/LoginPage">Order Now</a>
                )}
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3">
                  <div className="single-menu">
                    <img className="img-fluid" src="img/menu-9.jpg" />
                    <h4>Vietnamese</h4>
                  
                    <p>Light and fresh cuisine, emphasizing herbs, rice noodles, and balanced flavors.</p>
                    {token ? (
                     <a href="/OrderBook">Order Now</a>
                ) : (
                  
                      <a href="/LoginPage">Order Now</a>
                )}
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 ">
                  <div className="single-menu">
                    <img className="img-fluid" src="img/menu-9.jpg" />
                    <h4>American</h4>
                  
                    <p>Varied cuisine reflecting multicultural influences, famous for burgers, BBQ, and comfort foods.</p>
                    {token ? (
                     <a href="/OrderBook">Order Now</a>
                ) : (
                  
                      <a href="/LoginPage">Order Now</a>
                )}
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 ">
                  <div className="single-menu">
                    <img className="img-fluid" src="img/menu-9.jpg" />
                    <h4>Spanish</h4>
                  
                    <p>Diverse cuisine with flavors from seafood, paellas, and tapas, often enjoyed with wine.</p>
                    {token ? (
                     <a href="/OrderBook">Order Now</a>
                ) : (
                  
                      <a href="/LoginPage">Order Now</a>
                )}
                  </div>
                </div>
              

              </div>
            </div>
          </section>
          {/* Menu Section End*/}
          {/* Team Section Start */}
          <section id="team">
            <div className="container">
              <div className="section-header">
                <h3>Meet Our Chef</h3>
              </div>
              <div className="row">
                <div className="col-sm-6 col-md-3">
                  <div className="single-team">
                    <img src="img/team-1.jpg" alt="" />
                    <h4>Don Dennis</h4>
                    <p>Assistant Chef</p>
                    <ul className="icon">
                      <li><a href="#" className="fa fa-twitter" /></li>
                      <li><a href="#" className="fa fa-facebook" /></li>
                      <li><a href="#" className="fa fa-google-plus" /></li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="single-team">
                    <img src="img/team-2.jpg" alt="" />
                    <h4>Mary Tejeda</h4>
                    <p>Assistant Chef</p>
                    <ul className="icon">
                      <li><a href="#" className="fa fa-twitter" /></li>
                      <li><a href="#" className="fa fa-facebook" /></li>
                      <li><a href="#" className="fa fa-google-plus" /></li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="single-team">
                    <img src="img/team-3.jpg" alt="" />
                    <h4>Scott Williams</h4>
                    <p>Assistant Chef</p>
                    <ul className="icon">
                      <li><a href="#" className="fa fa-twitter" /></li>
                      <li><a href="#" className="fa fa-facebook" /></li>
                      <li><a href="#" className="fa fa-google-plus" /></li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="single-team">
                    <img src="img/team-4.jpg" alt="" />
                    <h4>Mary Hall</h4>
                    <p>Assistant Chef</p>
                    <ul className="icon">
                      <li><a href="#" className="fa fa-twitter" /></li>
                      <li><a href="#" className="fa fa-facebook" /></li>
                      <li><a href="#" className="fa fa-google-plus" /></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Team Section End */}
          {/* Testimonials Section Start */}
          <section id="testimonials" className="section-bg wow fadeInUp">
            <div className="container">
              <div className="section-header">
                <h3>Our Food Lovers</h3>
              </div>
              <div className="owl-carousel testimonials-carousel">
                <div className="row testimonial-item">
                  <div className="col-sm-4">
                    <img src="img/testimonial-1.jpg" className="testimonial-img" alt="" />
                  </div>
                  <div className="col-sm-8">
                    <div className="testimonial-content">
                      <div className="content">
                        <h3>Jamie D. Boyd</h3>
                        <h4>Oral Radiologist</h4>
                        <p>
                          <i className="fa fa-quote-left" />
                          Commodo sed hendrerit id, posuere tempus odio. Phasellus vel leo aliquam, interdum massa quis, aliquam sapien. Aliquam erat volutpat. Etiam nec feugiat libero. Phasellus in ipsum nunc.
                          <i className="fa fa-quote-right" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row testimonial-item">
                  <div className="col-sm-4">
                    <img src="img/testimonial-2.jpg" className="testimonial-img" alt="" />
                  </div>
                  <div className="col-sm-8">
                    <div className="testimonial-content">
                      <div className="content">
                        <h3>Albert J. Cerrato</h3>
                        <h4>Craft Artist</h4>
                        <p>
                          <i className="fa fa-quote-left" />
                          Proin ut dui dictum ligula condimentum cursus. Ut orci arcu, commodo sed hendrerit id, posuere tempus odio. Phasellus vel leo aliquam, interdum massa quis, aliquam sapien. Aliquam erat volutpat
                          <i className="fa fa-quote-right" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row testimonial-item">
                  <div className="col-sm-4">
                    <img src="img/testimonial-3.jpg" className="testimonial-img" alt="" />
                  </div>
                  <div className="col-sm-8">
                    <div className="testimonial-content">
                      <div className="content">
                        <h3>Theresa R. Wood</h3>
                        <h4>Prepress Technician</h4>
                        <p>
                          <i className="fa fa-quote-left" />
                          Dictum ligula condimentum cursus commodo sed hendrerit id, posuere tempus odio. Phasellus vel leo aliquam, interdum massa quis, aliquam sapien. Aliquam erat volutpat. Etiam nec ultricies semper risus.
                          <i className="fa fa-quote-right" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Testimonials Section End */}
          {/* Contact Section Start */}
          <section id="contact">
            <div className="container">
              <div className="section-header">
                <h3>Contact Us</h3>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="contact-col-1">
                    <div className="contact-detail">
                      <div className="contact-hours">
<h5 ><a href="/PrivacyPolicy">Privacy Policy</a></h5>
                      </div>
                      <div className="contact-info">
                        <h4>Contact Info</h4>
                        <p>4137  State Street, CA, USA</p>
                        <p><a href="tel:+91 95952 64988 ">+91 95952 64988</a></p>
                        <p><a href="mailto:info@example.com">info@example.com</a></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="contact-col-2">
                    <div className="contact-form">
                      <form>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <input type="text" className="form-control" placeholder="Your Name" required="required" />
                          </div>
                          <div className="form-group col-md-6">
                            <input type="email" className="form-control" placeholder="Your Email" required="required" />
                          </div>
                        </div>
                        <div className="form-group">
                          <input type="text" className="form-control" placeholder="Subject" required="required" />
                        </div>
                        <div className="form-group">
                          <textarea className="form-control" rows={5} placeholder="Message" required="required" defaultValue={""} />
                        </div>
                        <div><button type="submit">Send Message</button></div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Contact end */}
          {/* Support Section Start */}
          <section id="support">
            <div className="container">
              <h1>
                Need Book CHef ? Call Us 24/7 at +91 95952 64988
              </h1>
            </div>
          </section>
          {/* Support Section end */}
          {/* Footer Start */}
          {/* Footer end */}
          <a href="#" className="back-to-top"><i className="fa fa-chevron-up" /></a>
        </main>
        {/* JavaScript Libraries */}
        {/* Main Javascript File */}
      </div>
    );
  }

}



export default MainHomeComponent;

