import React, { Component } from 'react';
import axios from 'axios';
import emailjs from 'emailjs-com';
import { Link } from 'react-router-dom';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLink: '/', // Initialize with home link or any default link
    };
  }

  setActiveLink = (link) => {
    this.setState({ activeLink: link });
  };

  componentDidMount() {
    // Initialize EmailJS
    emailjs.init('2LkXttzKAVGsn4L25');
  }


  render() {
    const { activeLink } = this.state;

    const { alertMessage } = this.state;
    return (
      <div>
        <meta charSet="utf-8" />
        <title>BD Restaurant</title>
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <meta content name="keywords" />
        <meta content name="description" />
        {/* Favicons */}
        <link href="img/favicon.ico" rel="icon" />
        <link href="img/apple-touch-icon.png" rel="apple-touch-icon" />
        {/* Google Fonts */}
        <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600|Nunito:600,700,800,900" rel="stylesheet" />
        {/* Bootstrap CSS File */}
        <link href="vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet" />
        {/* Libraries CSS Files */}
        <link href="vendor/font-awesome/css/font-awesome.min.css" rel="stylesheet" />
        <link href="vendor/animate/animate.min.css" rel="stylesheet" />
        <link href="vendor/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
        <link href="vendor/tempusdominus/css/tempusdominus-bootstrap-4.min.css" rel="stylesheet" />
        {/* Main Stylesheet File */}
        <link href="css/style.css" rel="stylesheet" />
        <main id="main">

          <section id="top-header">
            <div className="logo">
              <img src="img/logo.png" />
            </div>
            <div id="header-slider" className="carousel slide" data-ride="carousel">
              <ol className="carousel-indicators">
                <li data-target="#header-slider" data-slide-to={0} className="active" />
                <li data-target="#header-slider" data-slide-to={1} />
                <li data-target="#header-slider" data-slide-to={2} />
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="fill" style={{ backgroundImage: 'url("img/slider-1.jpg")' }} />
                  <div className="carousel-caption">
                    <h2 className="animated fadeInLeft">Welcome to 5 * Servies in our Home </h2>
                    <p className="animated fadeInUp">Lorem ipsum dolor sit amet consectetur adipisicing elit</p>
                    <p className="animated fadeInUp"><a className="btn btn-transparent btn-rounded btn-large">Learn More</a></p>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="fill" style={{ backgroundImage: 'url("img/slider-2.jpg")' }} />
                  <div className="carousel-caption">
                    <h2 className="animated fadeInDown">Comfortable Dining Room</h2>
                    <p className="animated fadeInUp">Lorem ipsum dolor sit amet consectetur adipisicing elit</p>
                    <p className="animated fadeInUp"><a className="btn btn-transparent btn-rounded btn-large">Learn More</a></p>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="fill" style={{ backgroundImage: 'url("img/slider-3.jpg")' }} />
                  <div className="carousel-caption">
                    <h2 className="animated fadeInRight">Nice Space</h2>
                    <p className="animated fadeInRight">Lorem ipsum dolor sit amet consectetur adipisicing elit</p>
                    <p className="animated fadeInRight"><a className="btn btn-transparent btn-rounded btn-large">Learn More</a></p>
                  </div>
                </div>
              </div>
              <a className="carousel-control-prev" href="#header-slider" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true" />
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#header-slider" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true" />
                <span className="sr-only">Next</span>
              </a>
            </div>
          </section>
          {/* Top Header End */}
          {/* Header Start */}
          <header id="header">
        <div className="container">
          <nav id="nav-menu-container">
            <ul className="nav-menu">
              <li
                className={activeLink === '/' ? 'menu-active' : ''}
                onClick={() => this.setActiveLink('/')}
              >
                <Link to="/">Home</Link>
              </li>

              <li
                className={activeLink === '/OrderBook' ? 'menu-active' : ''}
                onClick={() => this.setActiveLink('/OrderBook')}
              >
                <Link to="/OrderBook">Online Order</Link>
              </li>

              <li
                className={activeLink === '/LoginPage' ? 'menu-active' : ''}
                onClick={() => this.setActiveLink('/LoginPage')}
              >
                <Link to="/LoginPage">Login</Link>
              </li>

              <li
                className={activeLink === '/ContactPage' ? 'menu-active' : ''}
                onClick={() => this.setActiveLink('/ContactPage')}
              >
                <Link to="/ContactPage">Contact</Link>
              </li>

              <li >
                <a
                 style={{ color: 'yellow' }}
                color='yello'
                  onClick={() => {
                    localStorage.clear();
                    sessionStorage.clear();
                    console.log("Storage cleared");
                    this.setActiveLink('/'); // Redirect to home or set default on logout
                  }}
                >
                  Logout
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
        </main>
      </div>
    )
  }
}
export default Header;