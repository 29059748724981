import React, { Component } from 'react';
import axios from 'axios';
import emailjs from 'emailjs-com';
import { Link } from 'react-router-dom';
import Header from './Header';
class LoginPage extends Component {
  state = {
    name: '',
    email: '',
    mobileNumber: '',
    password: '',
    loginEmail: '',
    loginPassword: '',
    alertMessage: '',
    loginAlertMessage: ''
  };

  componentDidMount() {
    // Initialize EmailJS
    emailjs.init('2LkXttzKAVGsn4L25');
  }
  handleLoginSubmit = (event) => {
    event.preventDefault();
    const { loginEmail, loginPassword } = this.state;

    const apiURL = 'https://as.providenceinternational.in/api/users/login';

    const payload = {
        email: loginEmail,
        password: loginPassword
    };

    axios.post(apiURL, payload)
        .then((response) => {
            console.log(response);

            // Check if the response contains the expected message
            if (response && response.data.message === "Logged in successfully") {
                // Store token in local storage
                localStorage.setItem('token', response.data.data.token);
                this.setState({ loginAlertMessage: 'Login Successful!' });
                // this.props.history.push('/'); // Redirect to home
            } else {
                this.setState({ loginAlertMessage: 'Login Failed!xx' });
            }
        })
        .catch((error) => {
            console.error('Login error!', error);
            this.setState({ loginAlertMessage: 'Login Failed!' });
        });
};



  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const { name, email, mobileNumber, password } = this.state;

    // API URL
    const apiURL = 'https://as.providenceinternational.in/api/users/register';
    
    // Payload to be sent to the API
    const payload = {
      name,
      email,
      phone: mobileNumber,
      password,
    };

    // Making POST request to the API
    axios.post(apiURL, payload)
      .then((response) => {
        console.log(response.data);
        this.setState({ alertMessage: 'Registration Successful!' });
        alert('Registration Successful!');
        window.location.reload();
      })
      .catch((error) => {
        console.error('There was an error!', error);
        this.setState({ alertMessage: 'Registration Failed!' });
      });
  };

  render() {
    const { name, email, mobileNumber, password, loginEmail, loginPassword, alertMessage, loginAlertMessage } = this.state;
    return (
      <div>
        <meta charSet="utf-8" />
        <title>BD Restaurant</title>
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <meta content name="keywords" />
        <meta content name="description" />
        {/* Favicons */}
        <link href="img/favicon.ico" rel="icon" />
        <link href="img/apple-touch-icon.png" rel="apple-touch-icon" />
        {/* Google Fonts */}
        <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600|Nunito:600,700,800,900" rel="stylesheet" /> 
        {/* Bootstrap CSS File */}
        <link href="vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet" />
        {/* Libraries CSS Files */}
        <link href="vendor/font-awesome/css/font-awesome.min.css" rel="stylesheet" />
        <link href="vendor/animate/animate.min.css" rel="stylesheet" />
        <link href="vendor/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
        <link href="vendor/tempusdominus/css/tempusdominus-bootstrap-4.min.css" rel="stylesheet" />
        {/* Main Stylesheet File */}
        <link href="css/style.css" rel="stylesheet" />
        <main id="main">
          {/* Banner Header Start */}
          <Header></Header>
          
          {/* Header End */}
          {/* Login Section Start */}
          <section id="login">
            <div className="container">
              <div className="section-header">
                <h3>Registration / Login</h3>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="login-col-1">
                    <div className="login-form">
                      {/* <form>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <input type="text" className="form-control" placeholder="Your Name" required="required" />
                          </div>
                          <div className="form-group col-md-6">
                            <input type="email" className="form-control" placeholder="Your Email" required="required" />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <input type="text" className="form-control" placeholder="Your Mobile" required="required" />
                          </div>
                          <div className="form-group col-md-6">
                            <input type="password" className="form-control" placeholder="Your Password" required="required" />
                          </div>
                        </div>
                    
                        <div><button type="submit">Regiester Now</button></div>
                      </form> */}
                      <form onSubmit={this.handleSubmit}>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Name"
                        name="name"
                        value={name}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Your Email"
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Mobile"
                        name="mobileNumber"
                        value={mobileNumber}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Your Password"
                        name="password"
                        value={password}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <button type="submit">Register Now</button>
                  </div>
                </form>
                {alertMessage && <p>{alertMessage}</p>}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="login-col-2">
                    <div className="login-form">
                      {/* <form>
                        <div className="form-group">
                          <input type="email" className="form-control" placeholder="Your Email" required="required" />
                        </div>
                        <div className="form-group">
                          <input type="password" className="form-control" placeholder="Your Password" required="required" />
                        </div>
                        <div><button type="submit">Send Message</button></div>
                      </form> */}
                      <form onSubmit={this.handleLoginSubmit}>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Email"
                      name="loginEmail"
                      value={loginEmail}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Your Password"
                      name="loginPassword"
                      value={loginPassword}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div>
                    <button type="submit">Login</button>
                  </div>
                </form>
                {loginAlertMessage && <p>{loginAlertMessage}</p>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Login Section End */}
          {/* Support Section Start */}
          <section id="support">
            <div className="container">
              <h1>
                Need Reservations? Call Us 24/7 at +1-234-567-8900
              </h1>
            </div>
          </section>
          {/* Support Section end */}
          {/* Footer Start */}
          <footer id="footer">
            <div className="container">
              <div className="row">
                <div className="col-sm-6">
                  <div className="copyright">
                    <p>© Copyright <a href>Your Site Name</a>. All Rights Reserved</p>
                    {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
                    <p>Template By <a href="https://htmlcodex.com">HTML Codex</a></p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <form className="form-inline">
                    <label>Get Latest Food Information</label>
                    <div className="form-group">
                      <input type="email" className="form-control" placeholder="Enter Your Email" />
                    </div>
                    <button type="submit" className="btn">Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </footer>
          {/* Footer end */}
        </main>
        {/* JavaScript Libraries */}
        {/* Main Javascript File */}
      </div>
    );
  }
}


export default LoginPage;

