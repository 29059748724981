// // App.js
// import React from 'react';
// import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
// import HomePageComponent from './components/HomePageComponent';
// import AboutComponent from './components/AboutComponent';
// import ContactComponent from './components/ContactComponent'
// const App = () => {
//   return (
//     <Router>
//       <div>
        

//           <Routes>
//           <Route path="/home" element={<HomePageComponent />} />
//           <Route path="/" element={<AboutComponent />} />
//           <Route path="/contact" element={<ContactComponent/>}/>
//         </Routes>
//       </div>
//     </Router>
//   );
// };











import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import LoginPage from'./components/LoginPage'

 import MainHomeComponent from './components/MainHomeComponent'
 import OrderBook from './components/OrderBook';
 import ContactPage from './components/Contact';
 import Preview from './components/Preview';
 import AdminDashboard from './components/AdminDashboard';
 import AdminLogin from './components/AdminLogin';
import RefundCancellationPolicy from './components/RefundCancellationPolicy';

const LoginPageWithNavigate = (props) => {
  const navigate = useNavigate();
  return <LoginPage {...props} navigate={navigate} />;
};

function App() {
  return (
   <Routes>
     <Route path='/' element={<MainHomeComponent/>}></Route>
     <Route path='/LoginPage' element={<LoginPageWithNavigate/>}></Route>
     <Route path='/OrderBook' element={<OrderBook/>}></Route>
     <Route path='/ContactPage' element={<ContactPage/>}></Route>
     <Route path='/Preview' element={<Preview/>}></Route>
     <Route path='/SupperAdminDashboard' element={<AdminDashboard/>}></Route>
     <Route path='/SupperAdminLogin' element={<AdminLogin/>}></Route>
     <Route path='/PrivacyPolicy' element={<RefundCancellationPolicy/>}></Route>


   </Routes>
  );
}

export default App;
