import React, { Component } from 'react';
import axios from 'axios';
// import './AdminDashboard.css';

class AdminDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalOrders: 0,
            totalChefs: 0,
            totalCuisines: 0,
            showModal: false,        // Controls modal visibility
            modalType: '',           // Controls which modal form to show
            newCuisineName: '',
            plates: [],              // List of plates for viewing
            chefs: [],               // List of chefs for viewing
            cuisines: [],            // List of cuisines for viewing
            adminToken: '',          // Admin token for authentication
            plateData: {
                requiredChefs: '',
                basePrice: '',
                plateCount: '',
            },
            chefData: {
                name: '',
                cuisine: '',
            },
        };
    }

    componentDidMount() {
        this.fetchCounts();
    }

    fetchCounts = async () => {
        try {
            const orders = await axios.get('https://your-api/orders/count');
            const chefs = await axios.get('https://your-api/chefs/count');
            const cuisines = await axios.get('https://your-api/cuisines/count');

            this.setState({
                totalOrders: orders.data.count,
                totalChefs: chefs.data.count,
                totalCuisines: cuisines.data.count,
            });
        } catch (error) {
            console.error('Error fetching counts:', error);
        }
    };

    openModal = (type) => {
        this.setState({ showModal: true, modalType: type }, async () => {
            if (type === 'viewChef') {
                const adminToken = localStorage.getItem('adminToken');
                const headers = {
                    Authorization: `Bearer ${adminToken}`,
                    'Content-Type': 'application/json',
                };
                
                try {
                    const response = await fetch('https://as.providenceinternational.in/api/chefs/get', {
                        method: 'GET',
                        headers: headers,
                    });
                    const data = await response.json();
                    if (data.success) {
                        this.setState({ chefs: data.data });
                    } else {
                        console.error('Failed to fetch chefs:', data);
                    }
                } catch (error) {
                    console.error('Error fetching chefs:', error);
                }
            } else if (type === 'viewCuisine') {
                try {
                    const response = await fetch('https://as.providenceinternational.in/api/cuisines/getall');
                    const data = await response.json();
                    if (data.success) {
                        this.setState({ cuisines: data.data });
                    } else {
                        console.error('Failed to fetch cuisines:', data);
                    }
                } catch (error) {
                    console.error('Error fetching cuisines:', error);
                }
            } else if (type === 'viewPlate') {
                try {
                    const response = await fetch('https://as.providenceinternational.in/api/plates/getall');
                    const data = await response.json();
                    if (data.success) {
                        this.setState({ plates: data.data });
                    } else {
                        console.error('Failed to fetch plates:', data);
                    }
                } catch (error) {
                    console.error('Error fetching plates:', error);
                }
            }
        });
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            modalType: '',
            newCuisineName: '',
            plateData: { requiredChefs: '', basePrice: '', plateCount: '' },
            chefData: { name: '', cuisine: '' },
            plates: [],
            chefs: [],
            cuisines: [],
        });
    };

    handleInputChange = (e, field, formType) => {
        if (formType === 'cuisine') {
            this.setState({ newCuisineName: e.target.value });
        } else if (formType === 'plate') {
            this.setState({ plateData: { ...this.state.plateData, [field]: e.target.value } });
        } else if (formType === 'chef') {
            this.setState({ chefData: { ...this.state.chefData, [field]: e.target.value } });
        }
    };

    handleSubmit = async () => {
        const { modalType, newCuisineName, plateData, chefData } = this.state;
        const adminToken = localStorage.getItem('adminToken');
        
        try {
            const headers = {
                Authorization: `Bearer ${adminToken}`,
                'Content-Type': 'application/json',
            };
    
            let response;
            let successMessage = '';
            
            if (modalType === 'cuisine') {
                response = await axios.post('http://as.providenceinternational.in/api/cuisines/create', 
                    { name: newCuisineName }, 
                    { headers }
                );
                successMessage = 'Cuisine added successfully!';
            } else if (modalType === 'plate') {
                response = await axios.post('http://as.providenceinternational.in/api/plates/create', 
                    plateData, 
                    { headers }
                );
                successMessage = 'Plate added successfully!';
            } else if (modalType === 'chef') {
                response = await axios.post('http://as.providenceinternational.in/api/chefs/create', 
                    chefData, 
                    { headers }
                );
                successMessage = 'Chef added successfully!';
            }
    
            // Check if response indicates a successful operation
            if (response.data.success) {
                alert(response.data.message || successMessage);
                this.closeModal();
                this.fetchCounts(); // Refresh counts after addition
            } else {
                alert(response.data.message || 'Failed to add data. Please try again.');
            }
            
        } catch (error) {
            console.error('Error submitting form:', error);
    
            // Check if the error is due to an invalid or expired token
            if (error.response && error.response.status === 401) {
                alert('Session expired or invalid token. Redirecting to login page...');
                localStorage.removeItem('adminToken'); // Clear invalid token
                window.location.href = '/SupperAdminLogin';   // Redirect to login page
            } else {
                alert(error.response?.data?.message || 'Failed to submit form. Please try again.');
            }
        }
    };
    

    renderModalContent = () => {
        const { modalType, newCuisineName, plateData, chefData, plates, chefs, cuisines } = this.state;

        switch (modalType) {
            case 'cuisine':
                return (
                    <div>
                        <h2>Add New Cuisine</h2>
                        <input
                        className="form-group"
                            type="text"
                            placeholder="Cuisine Name"
                            required
                            value={newCuisineName}
                            onChange={(e) => this.handleInputChange(e, 'name', 'cuisine')}
                        />
                    </div>
                );
            case 'plate':
                return (
                    <div>
                        <h2>Add New Plate</h2>
                        <input
                        className="form-group"
                            type="number"
                            placeholder="Required Chefs"
                            value={plateData.requiredChefs}
                            required
                            onChange={(e) => this.handleInputChange(e, 'requiredChefs', 'plate')}
                        />
                        <input
                        className="form-group"
                            type="number"
                            placeholder="Base Price"
                            value={plateData.basePrice
                                
                            }
                            required
                            onChange={(e) => this.handleInputChange(e, 'basePrice', 'plate')}
                        />
                        <input
                        className="form-group"
                            type="number"
                            placeholder="Plate Count"
                            value={plateData.plateCount}
                            required
                            onChange={(e) => this.handleInputChange(e, 'plateCount', 'plate')}
                        />
                    </div>
                );
            case 'chef':
                return (
                    <div>

                        <h2>Add New Chef</h2>
                        <input
                        className="form-group"
                            type="text"
                            placeholder="Chef Name"
                            value={chefData.name}
                            required
                            onChange={(e) => this.handleInputChange(e, 'name', 'chef')}
                        />
                        <input
                        className="form-group"
                            type="text"
                            placeholder="Cuisine"
                            value={chefData.cuisine}
                            required
                            onChange={(e) => this.handleInputChange(e, 'cuisine', 'chef')}
                        />
                    </div>
                );
            case 'viewPlate':
                return (
                    <div>
                        <h2>View Plates</h2>
                        {Array.isArray(plates) && plates.length > 0 ? (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Required Chefs</th>
                                        <th>Base Price</th>
                                        <th>Plate Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {plates.map((plate, index) => (
                                        <tr key={index}>
                                            <td>{plate.requiredChefs}</td>
                                            <td>{plate.basePrice}</td>
                                            <td>{plate.plateCount}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>No plates available.</p>
                        )}
                        <button onClick={this.closeModal}>Close</button>
                    </div>
                );
            case 'viewChef':
                return (
                    <div>
                        <h2>View Chefs</h2>
                        {Array.isArray(chefs) && chefs.length > 0 ? (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Cuisine</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {chefs.map((chef) => (
                                        <tr key={chef.id}>
                                            <td>{chef.name}</td>
                                            <td>{chef.cuisine}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>No chefs available.</p>
                        )}
                        <button onClick={this.closeModal}>Close</button>
                    </div>
                );
            case 'viewCuisine':
                return (
                    <div>
                        <h2>View Cuisines</h2>
                        {Array.isArray(cuisines) && cuisines.length > 0 ? (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Cuisine Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cuisines.map((cuisine, index) => (
                                        <tr key={index}>
                                            <td>{cuisine.name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>No cuisines available.</p>
                        )}
                        <button onClick={this.closeModal}>Close</button>
                    </div>
                );
            default:
                return null;
        }
    };
    render() {
        const { totalOrders, totalChefs, totalCuisines, showModal } = this.state;

        return (


            <div>
                <meta charSet="utf-8" />
                <title>BD Restaurant</title>
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                <meta content name="keywords" />
                <meta content name="description" />
                {/* Favicons */}
                <link href="img/favicon.ico" rel="icon" />
                <link href="img/apple-touch-icon.png" rel="apple-touch-icon" />
                {/* Google Fonts */}
                <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600|Nunito:600,700,800,900" rel="stylesheet" />
                {/* Bootstrap CSS File */}
                <link href="vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet" />
                {/* Libraries CSS Files */}
                <link href="vendor/font-awesome/css/font-awesome.min.css" rel="stylesheet" />
                <link href="vendor/animate/animate.min.css" rel="stylesheet" />
                <link href="vendor/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
                <link href="vendor/tempusdominus/css/tempusdominus-bootstrap-4.min.css" rel="stylesheet" />
                {/* Main Stylesheet File */}
                <link href="css/style.css" rel="stylesheet" />
                <main id="main">
                    {/* Banner Header Start */}
                    <section className="banner-header">
                        <div className="logo">
                            <img src="../img/logo.png" />
                        </div>
                    </section>
                    {/* Banner Header End */}
                    {/* Header Start */}
                   
                    <div className="admin-dashboard">
                        <h1 className="dashboard-title">Admin Dashboard</h1>
                        <div className="stats-container">
                            <div className="stat-box">
                                <p><strong>Total Orders</strong></p>
                                <h2>{totalOrders}</h2>
                            </div>
                            <div className="stat-box">
                                <p><strong>Total Chefs</strong></p>
                                <h2>{totalChefs}</h2>
                            </div>
                            <div className="stat-box">
                                <p><strong>Total Cuisines</strong></p>
                                <h2>{totalCuisines}</h2>
                            </div>
                        </div>


                        <div className="add-actions-container">
                            <div className="action-box" onClick={() => this.openModal('viewCuisine')}>
                                <p><strong>View Cuisine</strong></p>
                            </div>
                            <div className="action-box" onClick={() => this.openModal('viewChef')}>
                                <p><strong>View Chef</strong></p>
                            </div>
                            <div className="action-box" onClick={() => this.openModal('viewPlate')}>
                                <p><strong>View Plate</strong></p>
                            </div>
                        </div>

                        <div className="add-actions-container">
                            <div className="action-box" onClick={() => this.openModal('cuisine')}>
                                <p><strong>Add Cuisine</strong></p>
                            </div>
                            <div className="action-box" onClick={() => this.openModal('plate')}>
                                <p><strong>Add Plate</strong></p>
                            </div>
                            <div className="action-box" onClick={() => this.openModal('chef')}>
                                <p><strong>Add Chef</strong></p>
                            </div>
                        </div>

                    </div>

                    {showModal && (
                        <div className="modal-overlay">
                            <div className="modal-content">
                                {this.renderModalContent()}
                                <button onClick={this.handleSubmit}>Submit</button>
                                <button onClick={this.closeModal} className="close-button">Close</button>
                            </div>
                        </div>
                    )}
                </main>
            </div>

        );
    }
}

export default AdminDashboard;
