import React, { Component } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

class AdminLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            error: '',
            adminToken: '', // Token to be used after successful login
        };
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const { email, password } = this.state;

        try {
            const response = await axios.post('http://as.providenceinternational.in/api/admins/login', {
                email,
                password,
            });

            // Assuming the response contains the token in `response.data.token`
            const { token } = response.data;
            this.setState({ adminToken: token, error: '' });
            localStorage.setItem('adminToken', token);

            alert('Login successful!');
            this.setState({ redirectToPreview: true }); // Trigger redirection
            const adminToken = localStorage.getItem('adminToken');

            // Redirect to another page or store token as needed
            // You might want to store the token in localStorage if it should persist across page refreshes:
            // localStorage.setItem('adminToken', token);
        } catch (error) {
            console.error('Login failed:', error);
            this.setState({ error: 'Invalid email or password' });
        }
    };

    render() {
        if (this.state.redirectToPreview) {
            return <Navigate to="/SupperAdminDashboard" />; // Redirect after booking is complete
          }
        const { email, password, error } = this.state;

        return (

            <div>
                <meta charSet="utf-8" />
                <title>BD Restaurant</title>
                <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                <meta content name="keywords" />
                <meta content name="description" />
                {/* Favicons */}
                <link href="img/favicon.ico" rel="icon" />
                <link href="img/apple-touch-icon.png" rel="apple-touch-icon" />
                {/* Google Fonts */}
                <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600|Nunito:600,700,800,900" rel="stylesheet" />
                {/* Bootstrap CSS File */}
                <link href="vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet" />
                {/* Libraries CSS Files */}
                <link href="vendor/font-awesome/css/font-awesome.min.css" rel="stylesheet" />
                <link href="vendor/animate/animate.min.css" rel="stylesheet" />
                <link href="vendor/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
                <link href="vendor/tempusdominus/css/tempusdominus-bootstrap-4.min.css" rel="stylesheet" />
                {/* Main Stylesheet File */}
                <link href="css/style.css" rel="stylesheet" />
                <main id="main">
                    {/* Banner Header Start */}
                    <section className="banner-header">
                        <div className="logo">
                            <img src="img/logo.png" />
                        </div>
                    </section>
            <div style={styles.container}>
                <h2>Admin Login</h2>
                <form onSubmit={this.handleSubmit} style={styles.form}>
                    <div style={styles.formGroup}>
                        <label>Email:</label>
                        <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={this.handleInputChange}
                            style={styles.input}
                            required
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label>Password:</label>
                        <input
                            type="password"
                            name="password"
                            value={password}
                            onChange={this.handleInputChange}
                            style={styles.input}
                            required
                        />
                    </div>
                    {error && <p style={styles.error}>{error}</p>}
                    <button type="submit" style={styles.button}>Login</button>
                </form>
            </div>
            </main>
            </div>
            
        );
    }
}

const styles = {
    container: {
        width: '300px',
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    formGroup: {
        marginBottom: '15px',
    },
    input: {
        padding: '8px',
        fontSize: '16px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        width: '100%',
    },
    button: {
        padding: '10px',
        fontSize: '16px',
        borderRadius: '4px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
        fontSize: '14px',
        marginBottom: '15px',
    },
};

export default AdminLogin;
