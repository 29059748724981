import React from 'react';

const RefundCancellationPolicy = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>Refund and Cancellation Policy</h1>
      <p><strong>Customer Policy</strong></p>
      <p><i>(Updated as on 24th July, 2023)</i></p>
      <p>
        IdeaDeck Innovations Private Limited (“Company”) is the sole and exclusive owner of the brand BookMyChef 
        and consistently works towards the protection of its customers’ interests. Therefore, the Company follows 
        a reliable, easy, and streamlined process to facilitate the services being provided to its customers.
      </p>
      <p>
        This Refund Policy (hereinafter referred to as “Policy”) read with Terms and Conditions available on our 
        website <a href="https://bookmychef.app/" target="_blank" rel="noopener noreferrer">https://bookmychef.app/</a> 
        and mobile application BookMyChef (hereinafter collectively referred to as “Platform”) applies to services 
        offered to persons who are using or visiting the Platform.
      </p>
      <p>
        For the purposes of this Policy, “Customer(s)” shall mean users who purchase the services and book a Service 
        Partner through the Platform and “Service Partner” shall mean the professional who is offering their services 
        through the Platform.
      </p>
      
      <h2>Refunds</h2>
      <h3>1. Cancellation by Customer</h3>
      <ul>
        <li>
          Customers may cancel a booking on the Platform in advance to be eligible to receive a refund. However, 
          the Company reserves the right to charge a fee for such cancellation.
        </li>
        <li>
          Customers may be entitled to refund against such cancellation as enumerated below:
          <ul>
            <li>Full Refund will be given until the time the Service Partner is not assigned to the event.</li>
            <li>Full Refund for No show by the Service Partner.</li>
            <li>50% Refund for the following:
              <ul>
                <li>After the chef is assigned and until the ingredients are dispatched or</li>
                <li>12 hours before the Service Partner’s estimated time of arrival.</li>
              </ul>
            </li>
            <li>No Refund for the following:
              <ul>
                <li>Customer missed a booking, and/or nobody present at the location.</li>
                <li>Service Partner arrives but the ingredients/equipment are not ready.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          The Company shall provide the refund to the Customers via the original mode of payment opted by the 
          Customer (e.g., credit or debit card), if possible, provided that the Company will have no liability 
          for refunds in excess of the final booking price.
        </li>
        <li>
          The Company may also provide the refund through a credit note or the original mode of payment, 
          depending upon the Customer’s preference.
        </li>
        <li>
          In case of refunds, it may take up to 10 (ten) business days for the Company to initiate the refund 
          to the original payment source of the Customer.
        </li>
      </ul>

      <h3>2. Cancellation by Company</h3>
      <ul>
        <li>
          The Company may cancel the booking at any time in the event of unavoidable circumstances or incidents 
          which are deemed beyond the Company’s control and consequently affects the availability of the Service 
          Partner booked for an event. In such cases, fees paid against such booking will be refunded to the 
          Customer in full.
        </li>
        <li>
          However, should the Company cancel the booking in case of a material breach by the Customer or in case 
          of a serious misconduct on part of any Customer, fee paid against the booking will not be refunded.
        </li>
      </ul>

      <h2>Chef/Service Partner Policy</h2>
      <p>
        IdeaDeck Innovations Private Limited (“Company”) is the sole and exclusive owner of the brand BookMyChef 
        and consistently works towards the protection of its customers’ interests. Therefore, the Company follows 
        a reliable, easy, and streamlined process to facilitate the services being provided to its customers.
      </p>
      <p>
        This Refund and Cancellation Policy (hereinafter referred to as “Policy”) read with Terms and Conditions 
        available on our website <a href="https://www.bookmychef.online/" target="_blank" rel="noopener noreferrer">www.bookmychef.online</a> 
        and mobile application Bookmychef (hereinafter collectively referred to as “Platform”) applies to services 
        offered to persons who are using or visiting the Platform.
      </p>
      <p>
        For the purposes of this Policy “Customer(s)” shall mean users who purchase the services and book a 
        Service Partner through the Platform and “Service Partner” shall mean the professional who is offering 
        their services through the Platform.
      </p>

      <h2>Refunds</h2>
      <p>
        In lieu of the services provided by the Service Partner through the Platform, the Service Partner is 
        entitled to receive monetary consideration at a rate mutually discussed between the Company and the 
        Service Partner from time to time. Therefore, the Company is not entitled to refunds after the payment 
        has been made to the Service Partner.
      </p>

      <h3>Cancellation</h3>
      <h4>1. Cancellation by Service Partner</h4>
      <ul>
        <li>
          The Service Partner will be allowed to cancel 1 (One) booking in a month provided, they cancel such 
          booking 72 (Seventy-Two) hours prior to the visit to the Customer’s selected venue. However, the 
          Service Partner’s rating will be affected which might result in less chances of getting opportunities 
          to provide services.
        </li>
        <li>
          The number of cancellations shall not be carried forward to the following month.
        </li>
      </ul>

      <h4>2. Cancellation by Company</h4>
      <ul>
        <li>
          The Company may cancel the booking at any time in the event of unavoidable circumstances or incidents 
          which are deemed beyond the Company’s control.
        </li>
        <li>
          The Company may cancel the event if it is brought to its notice that the booking made is for an unlawful 
          event or is unsure of the safety of the Service Partner.
        </li>
        <li>
          However, should the Company cancel the booking in case of a material breach by the Service Partner or 
          in case of a serious misconduct on part of any Service partner, the Company shall be entitled to withhold 
          a fee to be paid against the services and recover all costs incurred due to such misconduct and/or breach.
        </li>
      </ul>

      <p>
        <strong>Disclaimer:</strong> The Company may amend this Policy at any time without any prior intimation 
        to the users. By continuing to use the Platform, the user consents to the amended terms of this Policy.
      </p>
    </div>
  );
};

export default RefundCancellationPolicy;
