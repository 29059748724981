import React, { Component } from 'react';
import axios from 'axios';
import emailjs from 'emailjs-com';

class ContactPage extends Component {
  state = {
    name: '',
    email: '',
    service: '',
  
    mobileNumber: '',
    specialRequest: '',
    alertMessage: ''
  };

  componentDidMount() {
    // Initialize EmailJS
    emailjs.init('2LkXttzKAVGsn4L25');
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const btn = document.getElementById('button');
    btn.value = 'Sending...';

    const serviceID = 'service_o02f64a';
    const templateID = 'template_zlkvznm';

    emailjs.sendForm(serviceID, templateID, event.target)
      .then(() => {
        btn.value = 'Send Email';
        alert('Sent!');
      })
      .catch((err) => {
        btn.value = 'Send Email';
        alert(JSON.stringify(err));
      });
  };

  render() {
    const { alertMessage } = this.state;
    return (
        <div>
          <meta charSet="utf-8" />
          <title>BD Restaurant</title>
          <meta content="width=device-width, initial-scale=1.0" name="viewport" />
          <meta content name="keywords" />
          <meta content name="description" />
          {/* Favicons */}
          <link href="img/favicon.ico" rel="icon" />
          <link href="img/apple-touch-icon.png" rel="apple-touch-icon" />
          {/* Google Fonts */}
          <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600|Nunito:600,700,800,900" rel="stylesheet" /> 
          {/* Bootstrap CSS File */}
          <link href="vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet" />
          {/* Libraries CSS Files */}
          <link href="vendor/font-awesome/css/font-awesome.min.css" rel="stylesheet" />
          <link href="vendor/animate/animate.min.css" rel="stylesheet" />
          <link href="vendor/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
          <link href="vendor/tempusdominus/css/tempusdominus-bootstrap-4.min.css" rel="stylesheet" />
          {/* Main Stylesheet File */}
          <link href="css/style.css" rel="stylesheet" />
          <main id="main">
            {/* Banner Header Start */}
            <section className="banner-header">
              <div className="logo">
                <img src="img/logo.png" />
              </div>
            </section>
            {/* Banner Header End */}
            {/* Header Start */}
            <header id="header">
          <div className="container">
            <nav id="nav-menu-container">
              <ul className="nav-menu">
                <li className="menu-active"><a href="/">Home</a></li>
                {/* <li><a href="about.html">About</a></li>
                <li><a href="menu.html">Menu</a></li> */}
                <li><a href="/OrderBook">Online Order</a></li>
                <li className="menu-has-children"><a href="/LoginPage">Login</a>
                  
                </li>
                <li><a href="/ContactPage">Contact</a></li>
              </ul>
            </nav>
          </div>
        </header>
            {/* Header End */}
            {/* Contact Section Start */}
            <section id="contact">
              <div className="container">
                <div className="section-header">
                  <h3>Contact Us</h3>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="contact-col-1">
                      <div className="contact-detail">
                        <div className="contact-hours">
                          
                        </div>
                        <div className="contact-info">
                          <h4>Contact Info</h4>
                          <p>4137  State Street, CA, USA</p>
                          <p><a href="tel:+91 95952 64988">+91 95952 64988</a></p>
                          <p><a href="mailto:info@example.com">info@example.com</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="contact-col-2">
                      <div className="contact-form">
                        <form>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <input type="text" className="form-control" placeholder="Your Name" required="required" />
                            </div>
                            <div className="form-group col-md-6">
                              <input type="email" className="form-control" placeholder="Your Email" required="required" />
                            </div>
                          </div>
                          <div className="form-group">
                            <input type="text" className="form-control" placeholder="Subject" required="required" />
                          </div>
                          <div className="form-group">
                            <textarea className="form-control" rows={5} placeholder="Message" required="required" defaultValue={""} />
                          </div>
                          <div><button type="submit">Send Message</button></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Contact end */}
            {/* Support Section Start */}
            <section id="support">
              <div className="container">
                <h1>
                  Need Book Chef? Call Us 24/7 at +91 95952 64988
                </h1>
              </div>
            </section>
            {/* Support Section end */}
            {/* Footer Start */}
            <footer id="footer">
              <div className="container">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="copyright">
                      {/* <p>© Copyright <a href>Your Site Name</a>. All Rights Reserved</p> */}
                      {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
                      {/* <p>Template By <a href="https://htmlcodex.com">HTML Codex</a></p> */}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <form className="form-inline">
                      <label>Get Latest Food Information</label>
                      <div className="form-group">
                        <input type="email" className="form-control" placeholder="Enter Your Email" />
                      </div>
                      <button type="submit" className="btn">Submit</button>
                    </form>
                  </div>
                </div>
              </div>
            </footer>
            {/* Footer end */}
            <a href="#" className="back-to-top"><i className="fa fa-chevron-up" /></a>
          </main>
          {/* JavaScript Libraries */}
          {/* Main Javascript File */}
        </div>
      );
    }
  }
export default ContactPage;
