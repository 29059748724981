import React, { Component } from 'react';
import Header from './Header';

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingDetails: null,
    };
  }

  componentDidMount() {
    // Retrieve booking details from local storage
    const bookingDetails = JSON.parse(localStorage.getItem('bookingDetails'));
    this.setState({ bookingDetails });
    console.log("boobookingDetails",bookingDetails)
  }
  

  handlePayment = () => {
    const { bookingDetails } = this.state;
    const options = {
      key: 'rzp_test_4aeWS1FiadwqNv', // Replace with your Razorpay key
      amount: bookingDetails.bookingAmount * 100, // Razorpay amount is in paise (1 INR = 100 paise)
      currency: 'INR',
      name: 'Cristofer Rajmani',
      description: 'Order Payment',
      image: 'img/logo.png',
      handler: function (response) {
        alert('Payment successful');
        console.log('Payment Response:', response);
      },
      prefill: {
        name: 'Customer Name', // replace with customer data
        email: 'customer@example.com', // replace with customer data
        contact: '9999999999', // replace with customer data
      },
      notes: {
        address: bookingDetails?.address,
      },
      theme: {
        color: '#F37254',
      },
    };
  
    const rzp = new window.Razorpay(options); // Ensure this spelling is correct
    rzp.open();
  };
  

  render() {
    const { bookingDetails } = this.state;

    if (!bookingDetails) {
      return <p>Loading booking details...</p>; // Show loading while fetching data
    }

    return (
      <div>
        {/* Metadata, styles, and header as per your existing structure */}
       <Header></Header>
        <main id="main">
          <section id="booking">
            <div className="container">
              <header className="section-header">
                <h3>Booking</h3>
              </header>
              <div className="container">
                <h3>Your Booking Details</h3>
                <p><strong>Address:</strong> {bookingDetails?.address}</p>
                <p><strong>Booking Date:</strong> {bookingDetails?.booking_date}</p>
                <p><strong>Description:</strong> {bookingDetails?.description}</p>
                <p><strong>Plate ID:</strong> {bookingDetails?.plateId}</p>
                <p><strong>Total Price:</strong> {bookingDetails?.totalPrice}</p>
                <p><strong>Booking Amount:</strong> {bookingDetails?.bookingAmount}</p>
                <p><strong>Payment Status:</strong> {bookingDetails?.paymentStatus}</p>
                <p><strong>Cuisine ID:</strong> {bookingDetails?.cuisineId}</p>
                <button onClick={this.handlePayment} className="btn btn-primary">
                  Make a Payment
                </button>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default Preview;
