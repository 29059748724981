import React, { Component } from 'react';
import axios from 'axios';
import emailjs from 'emailjs-com';
import Header from './Header';

import { Navigate } from 'react-router-dom';

class OrderBook extends Component {
  constructor(props) {
    super(props);


    this.state = {
      cuisines: [],
      plates: [],  // Initializing cuisines as an empty array
      selectedCuisine: '',
      selectedPlate: '',
      address: '',
      booking_date: '',
      time: '',
      description: '',
      redirectToPreview: false, // New state for redirection

    };
  }
  componentDidMount() {
    // // Initialize EmailJS
    // emailjs.init('2LkXttzKAVGsn4L25');

    // Initialize EmailJS
    // emailjs.init('2LkXttzKAVGsn4L25');

    // Fetch cuisines and plates data
    axios.get('https://as.providenceinternational.in/api/cuisines/getall')
      .then(response => {
        if (response.data && Array.isArray(response.data.data)) {
          this.setState({ cuisines: response.data.data });
        } else {
          console.error("Unexpected response format:", response.data);
          this.setState({ cuisines: [] });
        }
      })
      .catch(error => {
        console.error('Error fetching cuisines:', error);
        this.setState({ cuisines: [] });
      });

    axios.get('https://as.providenceinternational.in/api/plates/getall')
      .then(response => {
        if (response.data && Array.isArray(response.data.data)) {
          this.setState({ plates: response.data.data });
        }
      })
      .catch(error => {
        console.error('Error fetching plates:', error);
      });
  }


  // handleSubmit = (event) => {
  //   event.preventDefault();
  //   const btn = document.getElementById('button');
  //   btn.value = 'Sending...';

  //   const serviceID = 'service_o02f64a';
  //   const templateID = 'template_zlkvznm';

  //   emailjs.sendForm(serviceID, templateID, event.target)
  //     .then(() => {
  //       btn.value = 'Send Email';
  //       alert('Sent!');
  //     })
  //     .catch((err) => {
  //       btn.value = 'Send Email';
  //       alert(JSON.stringify(err));
  //     });
  // };
  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleSubmit = (event) => {
    event.preventDefault();

    const token = localStorage.getItem('token'); // Retrieve the token from local storage
    const { address, booking_date, time, selectedPlate, selectedCuisine, description } = this.state;

    const payload = {
      address,
      booking_date,
      description,
      plateId: selectedPlate,
      cuisineId: selectedCuisine
    };

    const apiURL = 'https://as.providenceinternational.in/api/booking/bookings';

    axios.post(apiURL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(response => {
        console.log('Booking successful:', response.data);
        // Store the response data in local storage
        localStorage.setItem('bookingDetails', JSON.stringify(response.data.data));
        this.setState({ redirectToPreview: true }); // Trigger redirection
        // Navigate to the preview page using history
        // this.props.history.push('/Preview'); 
      })
      .catch(error => {
        console.error('Error making booking:', error);
        alert('There was an error making your booking. Please try again.');
      });
  };
  render() {
    if (this.state.redirectToPreview) {
      return <Navigate to="/Preview" />; // Redirect after booking is complete
    }
    const { alertMessage } = this.state;
    const { cuisines, plates, selectedCuisine, selectedPlate, address, booking_date, time, description } = this.state;

    return (
      <div>
        <meta charSet="utf-8" />
        <title>BD Restaurant</title>
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <meta content name="keywords" />
        <meta content name="description" />
        {/* Favicons */}
        <link href="img/favicon.ico" rel="icon" />
        <link href="img/apple-touch-icon.png" rel="apple-touch-icon" />
        {/* Google Fonts */}
        <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600|Nunito:600,700,800,900" rel="stylesheet" />
        {/* Bootstrap CSS File */}
        <link href="vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet" />
        {/* Libraries CSS Files */}
        <link href="vendor/font-awesome/css/font-awesome.min.css" rel="stylesheet" />
        <link href="vendor/animate/animate.min.css" rel="stylesheet" />
        <link href="vendor/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
        <link href="vendor/tempusdominus/css/tempusdominus-bootstrap-4.min.css" rel="stylesheet" />
        {/* Main Stylesheet File */}
        <link href="css/style.css" rel="stylesheet" />
        <main id="main">
          {/* Banner Header Start */}
          <section className="banner-header">
            <div className="logo">
              <img src="img/logo.png" />
            </div>
          </section>
          {/* Banner Header End */}
          {/* Header Start */}
        
        <Header></Header>
          <section id="booking">
            <div className="container">
              <header className="section-header">
                <h3>Booking</h3>
              </header>


              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="cuisine">Select Cuisine</label>
                      <select
                        id="cuisine"
                        name="selectedCuisine"
                        className="form-control"
                        value={selectedCuisine}
                        onChange={this.handleInputChange}
                      >
                        <option value="">Select a cuisine</option>
                        {cuisines.map((cuisine) => (
                          <option key={cuisine.id} value={cuisine.id}>
                            {cuisine.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="plates">Select Plates</label>
                      <select
                        id="plates"
                        name="selectedPlate"
                        className="form-control"
                        value={selectedPlate}
                        onChange={this.handleInputChange}
                      >
                        <option value="">Select plate count</option>
                        {plates.map((plate) => (
                          <option key={plate.id} value={plate.id}>
                            {plate.plateCount} Plates - ${plate.basePrice}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="address">Delivery Address</label>
                      <input
                        type="text"
                        id="address"
                        className="form-control"
                        name="address"
                        value={address}
                        onChange={this.handleInputChange}
                        placeholder="Enter your address"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="date">Select Date</label>
                      <input
                        type="date"
                        id="date"
                        className="form-control"
                        name="booking_date"
                        value={booking_date}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="time">Select Time</label>
                      <input
                        type="time"
                        id="time"
                        className="form-control"
                        name="time"
                        value={time}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="description">description</label>
                      <input
                        type="text"
                        id="description"
                        className="form-control"
                        name="description"
                        value={description}
                        onChange={this.handleInputChange}
                        placeholder="Enter your address"
                        required
                      />
                    </div>

                    <div className="button">
                      <button type="submit" className="btn btn-primary">Checkout</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>

          {/* Menu Section End*/}
          {/* Cart Section Start */}
          {/* <section id="cart">
              <div className="container">
                <header className="section-header">
                  <h3>Cart</h3>
                </header>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive-sm">
                      <table className="table table-bordered table-hover">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">Menu</th>
                            <th scope="col">Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Total</th>
                            <th scope="col">Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Lorem ipsum</th>
                            <td>$10</td>
                            <td><div className="quantity"><input type="text" defaultValue={1} /></div></td>
                            <td>$10</td>
                            <td><a href><i className="fa fa-trash-o" /></a></td>
                          </tr>
                          <tr>
                            <th scope="row">Proin Tortor</th>
                            <td>$15</td>
                            <td><div className="quantity"><input type="text" defaultValue={2} /></div></td>
                            <td>$30</td>
                            <td><a href><i className="fa fa-trash-o" /></a></td>
                          </tr>
                          <tr>
                            <th scope="row">Aliquam Erat</th>
                            <td>$20</td>
                            <td><div className="quantity"><input type="text" defaultValue={3} /></div></td>
                            <td>$60</td>
                            <td><a href><i className="fa fa-trash-o" /></a></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="button">
                      <button type="submit">Checkout</button>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
          {/* Cart Section End */}
          {/* Checkout Section Start */}
          {/* <section id="checkout">
              <div className="container">
                <header className="section-header">
                  <h3>Checkout</h3>
                </header>
                <div className="row form">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Full Name" />
                    </div>
                    <div className="form-group">
                      <input type="email" className="form-control" placeholder="Email" />
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Address" />
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="City" />
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <input type="text" className="form-control" placeholder="State" />
                      </div>
                      <div className="form-group col-md-6">
                        <input type="text" className="form-control" placeholder="Zip" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="cards">
                      <p>We Accept:</p>
                      <img src="img/credit-cards.png" />
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Name on Card" />
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Credit card number" />
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Exp Month" />
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <input type="text" className="form-control" placeholder="Exp Year" />
                      </div>
                      <div className="form-group col-md-6">
                        <input type="text" className="form-control" placeholder="CVV" />
                      </div>
                    </div>
                  </div>
                  <div className="button">
                    <button type="submit">Place Order</button>
                  </div>
                </div>
              </div>
            </section> */}
          {/* Checkout Section End */}
          {/* Support Section Start */}
          <section id="support">
            <div className="container">
              <h1>
                Need Book Chef? Call Us 24/7 at +91 95952 64988
              </h1>
            </div>
          </section>
          {/* Support Section end */}
          {/* Footer Start */}
          <footer id="footer">
            <div className="container">
              <div className="row">
                <div className="col-sm-6">
                  <div className="copyright">
                    {/* <p>© Copyright <a href>Your Site Name</a>. All Rights Reserved</p> */}
                    {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
                    {/* <p>Template By <a href="https://htmlcodex.com">HTML Codex</a></p> */}
                  </div>
                </div>
                <div className="col-sm-6">
                  <form className="form-inline">
                    <label>Get Latest Food Information</label>
                    <div className="form-group">
                      <input type="email" className="form-control" placeholder="Enter Your Email" />
                    </div>
                    <button type="submit" className="btn">Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </footer>
          {/* Footer end */}
          <a href="#" className="back-to-top"><i className="fa fa-chevron-up" /></a>
        </main>
        {/* JavaScript Libraries */}
        {/* Main Javascript File */}
      </div>
    );

  }
}
export default OrderBook;
